
import {
  defineComponent, ref, toRefs, computed
} from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmTable,
  },
  props: {
    year: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { year } = toRefs(props)

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Month', value: 'month-slot' },
      { text: 'Total calls', value: 'totalCalls' },
      { text: 'Total talk time', value: 'totalTalkTime' },
      { text: 'Costs', value: 'costs' },
    ])

    const tableItems = computed(() => [
      { monthShort: '01', month: `January ${year.value}`, totalCalls: '1,445', totalTalkTime: '12:33:18', costs: '$ 993.00' },
      { monthShort: '02', month: `February ${year.value}`, totalCalls: '2,543', totalTalkTime: '12:33:18', costs: '$ 138.00' },
      { monthShort: '03', month: `March ${year.value}`, totalCalls: '445', totalTalkTime: '12:33:18', costs: '$ 441.00' },
      { monthShort: '04', month: `April ${year.value}`, totalCalls: '123', totalTalkTime: '12:33:18', costs: '$ 451.00' },
      { monthShort: '05', month: `May ${year.value}`, totalCalls: '2,491', totalTalkTime: '12:33:18', costs: '$ 483.00' },
      { monthShort: '06', month: `June ${year.value}`, totalCalls: '301', totalTalkTime: '12:33:18', costs: '$ 180.00' },
      { monthShort: '07', month: `July ${year.value}`, totalCalls: '840', totalTalkTime: '12:33:18', costs: '$ 1,670.00' },
      { monthShort: '08', month: `August ${year.value}`, totalCalls: '1,993', totalTalkTime: '12:33:18', costs: '$ 448.00' },
      { monthShort: '09', month: `September ${year.value}`, totalCalls: '2,973', totalTalkTime: '12:33:18', costs: '$ 397.00' },
      { monthShort: '10', month: `October ${year.value}`, totalCalls: '75', totalTalkTime: '12:33:18', costs: '$ 217.00' },
      { monthShort: '11', month: `November ${year.value}`, totalCalls: '629', totalTalkTime: '12:33:18', costs: '$ 451.00' },
      { monthShort: '12', month: `December ${year.value}`, totalCalls: '1,114', totalTalkTime: '12:33:18', costs: '$ 448.00' },
    ])

    return {
      tableHeaders,
      tableItems,
    }
  },
})
