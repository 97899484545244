import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reporting_messages_calls_table_filter_year_month = _resolveComponent("reporting-messages-calls-table-filter-year-month")!
  const _component_reporting_calls_table_year = _resolveComponent("reporting-calls-table-year")!
  const _component_reporting_calls_stats = _resolveComponent("reporting-calls-stats")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_reporting_messages_calls_table_filter_year_month, {
      year: _ctx.year,
      years: _ctx.years,
      page: "calls",
      period: "year"
    }, null, 8, ["year", "years"]),
    _createVNode(_component_reporting_calls_table_year, { year: _ctx.year }, null, 8, ["year"]),
    _createVNode(_component_reporting_calls_stats, { type: "month" })
  ]))
}