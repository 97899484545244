
import type { PropType } from 'vue'
import {
  defineComponent, computed, toRefs
} from 'vue'
import { useRoute } from 'vue-router'
import ReportingMessagesCallsTableFilterYearMonth from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsTableFilterYearMonth.vue'
import ReportingCallsTableYear from '@/components/pages/reporting/messagesCalls/calls/ReportingCallsTableYear.vue'
import ReportingCallsStats from '@/components/pages/reporting/messagesCalls/calls/ReportingCallsStats.vue'
import type { ReportingMessagesStatsFullLine } from '@/definitions/reporting/messagesCalls/messages/types'

export default defineComponent({
  components: {
    ReportingMessagesCallsTableFilterYearMonth,
    ReportingCallsTableYear,
    ReportingCallsStats,
  },
  props: {
    statsFull: {
      type: Object as PropType<ReportingMessagesStatsFullLine[]>,
      required: true,
    },
  },
  setup(props) {
    const { statsFull } = toRefs(props)
    const route = useRoute()

    const year = computed(() => (Number(route.params.year)))

    const years = computed(() => statsFull.value.map((e) => e.year))

    return {
      year,
      years,
    }
  },
})
